import React from 'react'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { makeStyles, Grid, useMediaQuery } from '@material-ui/core'
import Master from '../components/Master'
import Posts from '../components/ListView/Posts/Posts'

const PostsPaginate = ({ data }) => {
    const posts = data.allPost.nodes
    const page = data.allPage.nodes[0]

    const useStyles = makeStyles(theme => ({
        center: {
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
        },
        width100: {
            width: '100%'
        },
        width90: {
            width: '100%'
        },
        paper: {
            padding: '10px'
        },
        paperLi: {
            padding: '10px',
            borderTop: 'solid 1px #EEE',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textDecoration: 'none',
            '&:first-child': {
                borderTop: 'none',
            },
            '& a': {
                textDecoration: 'none',
                color: '#555'
            },
            '& a:hover': {
                textDecoration: 'none',
                color: '#444'
            },
        }
    }));

    const classes = useStyles();

    const imageMediaQuery = useMediaQuery('(min-width:960px)');

    return (
        <Master title={page.title}>
            <GatsbySeo
                title={page.title}
                description={page.description}
                canonical={`${process.env.SITE_URL}/informativos`}
                noindex={false}
                nofollow={false}
                openGraph={{
                    url: `${process.env.SITE_URL}/informativos`,
                    title: page.title,
                    description: page.description,
                    images: [
                        {
                            url: `${process.env.SITE_IMAGE_OG_512}`,
                            width: 512,
                            height: 512,
                            alt: page.title
                        }
                    ],
                    site_name: `${process.env.SITE_NAME}`,
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <h1 className={classes.center}>{page.title}</h1>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    {imageMediaQuery ?
                        <img src={`https://images.unsplash.com/photo-1555421689-491a97ff2040?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80`} className={classes.width90} title={page.title} alt={page.title} /> :
                        <img className={classes.width100} src={`https://images.unsplash.com/photo-1555421689-491a97ff2040?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80`} title={page.title} alt={page.title} />}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div dangerouslySetInnerHTML={{ __html: page.details }} />
                </Grid>
            </Grid>
            <Posts posts={posts} />
        </Master>
    )
}

export const query = graphql`
    query($skip: Int!, $limit:Int!){
        allPost(
            sort: {fields:updatedAt, order: DESC}
            skip: $skip
            limit: $limit
        ) {
            nodes {
                id
                title
                slug
                description
                image
                imageThumbnail
            }
        }
        allPage(filter: {id: {eq: "5efa2b3a226aed4281876a6e"}}) {
            nodes {
                title
                description
                keywords
                details
            }
        }
    }
`;

export default PostsPaginate