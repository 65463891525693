import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'

const Service = props => {

    const useStyles = makeStyles({
        paperLi: {
            padding: '10px',
            borderTop: 'solid 1px #EEE',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textDecoration: 'none',
            '&:first-child': {
                borderTop: 'none',
            },
            '& a': {
                textDecoration: 'none',
                color: '#555'
            },
            '& a:hover': {
                textDecoration: 'none',
                color: '#444'
            },
        }
    })

    const classes = useStyles()

    return (
        <li key={props.id} className={classes.paperLi}>
            <Link to={`/informativos/${props.slug}`}>
                {`${moment(props.updatedAt).format('L')} - ${props.title} ${props.title}`}
            </Link>
        </li>
    )
}

export default Service