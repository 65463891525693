import React from 'react'
import { makeStyles, Paper } from '@material-ui/core'

import Post from './Post/Post'

const useStyles = makeStyles(theme => ({
    paper: {
        padding: '10px'
    },
}))

const Posts = props => {

    const classes = useStyles()

    return (
        <Paper className={classes.paper}>
            <ul>
                {props.posts.map(post => (
                    <Post
                        title={post.title}
                        slug={post.slug}
                        description={post.description}
                        imageThumbnail={post.imageThumbnail}
                    />
                ))}
            </ul>
        </Paper>
    )
}

export default Posts